import React, { Component } from "react"

const SUBMIT_MSG_SUCCESS = "Thank you! Your message was successfully sent."
const SUBMIT_QUOTE_SUCCESS =
  "Thank you! Your request for a quote was successfully sent."
const SHOW_QUOTE_FORM = false;

const encode = data => {
  return Object.keys(data)
    .map(key => encodeURIComponent(key) + "=" + encodeURIComponent(data[key]))
    .join("&")
}

export default class ContactForm extends Component {
  state = {
    isChecked: SHOW_QUOTE_FORM,
    name: "",
    email: "",
    subject: "",
    textarea: "",
    nameError: "",
    emailError: "",
    textareaError: "",
  }

  handleCheckbox = () => {
    this.setState({
      isChecked: !this.state.isChecked,
    })
  }

  handleChange = e => {
    e.preventDefault()
    this.setState({
      [e.target.name]: e.target.value,
    })
  }

  validate = () => {
    let nameError = ""
    let emailError = ""
    let textareaError = ""
    let emailRegEx = /^[a-zA-Z0-9._-]+@[a-zA-Z0-9.-]+\.[a-zA-Z]{2,4}$/

    if (!this.state.name) {
      nameError = "name cannot be blank"
    }

    if (!this.state.textarea) {
      textareaError = "Message cannot be blank"
    }

    // if (!this.state.email.includes("@")) {
    //   emailError = "invalid email"
    // }

    if (!emailRegEx.test(this.state.email)) {
      emailError = "invalid email"
    }

    if (emailError || nameError || textareaError) {
      this.setState({ emailError, nameError, textareaError })
      return false
    }

    return true
  }

  handleSubmitMessage = e => {
    e.preventDefault()

    const isValid = this.validate()
    if (isValid) {
      fetch("/", {
        method: "POST",
        headers: { "Content-Type": "application/x-www-form-urlencoded" },
        body: encode({ "form-name": "Message", ...this.state }),
      })
        .then(() => alert(SUBMIT_MSG_SUCCESS))
        .catch(error => alert(error))

      this.setState({
        name: "",
        email: "",
        textarea: "",
        nameError: "",
        emailError: "",
        textareaError: "",
      })
    }
    console.log(this.state)
  }

  handleSubmitQuatation = e => {
    e.preventDefault()
    const isValid = this.validate()
    if (isValid) {
      fetch("/", {
        method: "POST",
        headers: { "Content-Type": "application/x-www-form-urlencoded" },
        body: encode({ "form-name": "Quotation", ...this.state }),
      })
        .then(() => alert(SUBMIT_QUOTE_SUCCESS))
        .catch(error => alert(error))

      this.setState({
        name: "",
        email: "",
        subject: "",
        textarea: "",
        nameError: "",
        emailError: "",
        textareaError: "",
      })
    }
  }

  render() {
    const { name, email, subject, textarea, isChecked } = this.state
    return (
      <>
        <section className="contact-form-section" id="contact-form-section">
          {SHOW_QUOTE_FORM && (
          <div className="form-toggler">
            <label className="toggler">Message</label>
            <div className="toggle">
              <input
                type="checkbox"
                checked={this.state.isChecked}
                onChange={this.handleCheckbox}
                id="switcher"
                className="check"
              />
              <b className="b switch"></b>
            </div>
            <label className="toggler">Get Quote</label>
          </div>
          )}

          {!isChecked && (
            <div id="message" className="wrapper-full">
              <form
                name="Message"
                method="POST"
                data-netlify="true"
                netlify-honeypot="bot-field"
                onSubmit={this.handleSubmitMessage}
              >
                <input type="hidden" name="form-name" value="Message" />
                <div className="form-group">
                  <label>Name</label>
                  <input
                    type="text"
                    name="name"
                    value={name}
                    onChange={this.handleChange}
                    className={`${
                      this.state.nameError.length > 0
                        ? "form-control filed-error"
                        : "form-control"
                    } `}
                    placeholder="Your name"
                  />
                </div>
                <div className="form-group">
                  <label>Email</label>
                  <input
                    type="email"
                    name="email"
                    value={email}
                    onChange={this.handleChange}
                    className={`${
                      this.state.emailError.length > 0
                        ? "form-control filed-error"
                        : "form-control"
                    } `}
                    placeholder="Email Address"
                  />
                </div>
                <div className="form-group">
                  <label>Message</label>
                  <textarea
                    rows="5"
                    name="textarea"
                    value={textarea}
                    onChange={this.handleChange}
                    className={`${
                      this.state.textareaError.length > 0
                        ? "form-control filed-error"
                        : "form-control"
                    } `}
                    placeholder="Message"
                  ></textarea>
                </div>
                <button type="submit" className="btn btn-submit">
                  Submit
                </button>
              </form>
            </div>
          )}
          {SHOW_QUOTE_FORM && isChecked && (
            <div id="quotes" className="wrapper-full">
              <form
                onSubmit={this.handleSubmitQuatation}
                name="Quotation"
                method="POST"
                data-netlify="true"
                netlify-honeypot="bot-field"
              >
                <div className="form-group">
                  <label>Name</label>
                  <input
                    type="text"
                    name="name"
                    value={name}
                    onChange={this.handleChange}
                    className={`${
                      this.state.nameError.length > 0
                        ? "form-control filed-error"
                        : "form-control"
                    } `}
                    placeholder="Your name"
                  />
                </div>
                <div className="form-group">
                  <label>Email</label>
                  <input
                    type="email"
                    name="email"
                    value={email}
                    onChange={this.handleChange}
                    className={`${
                      this.state.emailError.length > 0
                        ? "form-control filed-error"
                        : "form-control"
                    } `}
                    placeholder="Email Address"
                  />
                </div>
                <div className="form-group">
                  <label>Subject</label>
                  <input
                    type="text"
                    name="subject"
                    value={subject}
                    onChange={this.handleChange}
                    className="form-control"
                    placeholder="Subject"
                  />
                </div>
                <div className="form-group">
                  <label>Message</label>
                  <textarea
                    rows="5"
                    name="textarea"
                    value={textarea}
                    onChange={this.handleChange}
                    className={`${
                      this.state.textareaError.length > 0
                        ? "form-control filed-error"
                        : "form-control"
                    } `}
                    placeholder="Message"
                  ></textarea>
                </div>
                <button type="submit" className="btn btn-submit">
                  Submit
                </button>
              </form>
            </div>
          )}
        </section>
      </>
    )
  }
}
