import React from "react"
import { Link } from "gatsby"

const FooterLinks = () => {
  return (
    <>
      <Link to="/imprint">Imprint</Link>
    </>
  )
}

export default FooterLinks
