import React from "react"

import envelope from "../images/envelope.svg"
import phone from "../images/phone.svg"
import ContactForm from "./ContactForm"

const ContactSection = ({className}) => {
  return (
    <>
      <section className={["contact-section", className || ""].join(" ")}>
        <div className="container">
          <div className="row justify-content-center">
            <div className="col-lg-10">
              <div className="contact-container">
                <div className="row">
                  <div className="col-lg-5">
                    <div className="contact-info">
                      <h3>Get in Touch</h3>
                      <p>
                        Let’s get a conversation started. We love to hear where
                        you’re at and help you strategize.
                      </p>
                      {/* <div className="company-name">
                        Pandigital Techlonogy Inc.
                      </div> */}
                      <ul>
                        <li>
                          <div className="media">
                            <img
                              src={envelope}
                              className="mr-3"
                              alt="Pandigita"
                            />
                            <div className="media-body">
                              contact@pandigita.com
                            </div>
                          </div>
                        </li>
                        <li>
                          <div className="media">
                            <img src={phone} className="mr-3" alt="Pandigita" />
                            <div className="media-body">
                              +49 15678741705 / +1 307 2073967
                            </div>
                          </div>
                        </li>
                      </ul>
                    </div>
                  </div>
                  <div className="col-lg-7">
                    <ContactForm />
                  </div>
                </div>
              </div>
            </div>
          </div>
        </div>
      </section>
    </>
  )
}

export default ContactSection
