import React from "react"
import LogoWhite from "../images/logo-white.svg"
import { Link } from "react-scroll"
import FooterLinks from "./FooterLinks"

const FooterSection = () => {
  const year = new Date().getFullYear()
  return (
    <>
      <section className="footer-section">
        <div className="container">
          <div className="row align-items-center">
            <div className="col-lg-8">
              <ul className="footer-link">
                {/* <li>
                  <a href="#">How we're better</a>
                </li>
                <li>
                  <a href="#">Our Work</a>
                </li>
                <li>
                  <a href="#">Career</a>
                </li>
                <li>
                  <a href="#">Terms & Conditions</a>
                </li>
                <li>
                  <a href="#">Privacy Policy</a>
                </li> */}
                <li>
                  <Link to="navbar" smooth={true} duration={1000} offset={-30}>
                    Jump to top
                  </Link>
                </li>
                <li>
                  <FooterLinks />
                </li>
              </ul>
            </div>
            <div className="col-lg-4">
              <div className="footer-number">
                <span>Contact us</span>
                <h3>+49 15678741705</h3>
              </div>
            </div>
            <div className="col-lg-12">
              <div className="copyright-section">
                <div className="row">
                  <div className="col-lg-6">
                    <div className="copyright-logo">
                      <img src={LogoWhite} alt="Pandigital" />
                    </div>
                  </div>
                  <div className="col-lg-6">
                    <div className="copyright-text">
                      <p>&copy; {year} Pandigita reserved.</p>
                    </div>
                  </div>
                </div>
              </div>
            </div>
          </div>
        </div>
      </section>
    </>
  )
}

export default FooterSection
